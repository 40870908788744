import {useEffect, useState} from 'react';
import bridge from '@vkontakte/vk-bridge';
import {ConfigProvider, AdaptivityProvider, AppRoot} from '@vkontakte/vkui';

import "@vkontakte/vkui/dist/vkui.css";

import {parseLaunchParams} from "../helpers/parseLaunchParams";

import {EventsFilterProvider} from '../store/eventsFilterContext';

import '../styles/globals.css'

function MyApp({Component, pageProps}) {
  const [platform, setPlatform] = useState()
  const isDesktop = platform === 'desktop_web'

  useEffect(() => {
    bridge.send("VKWebAppInit");
    const {vk_platform} = parseLaunchParams(window.location.search.slice(1))
    setPlatform(vk_platform)
  }, [])

  return (
    <ConfigProvider platform='ios'>
      <AdaptivityProvider hasMouse={isDesktop}>
        <AppRoot>
          <EventsFilterProvider>
            <Component {...pageProps} />
          </EventsFilterProvider>
        </AppRoot>
      </AdaptivityProvider>
    </ConfigProvider>
  )
}

export default MyApp
