import { createContext, useContext, useState } from 'react';
// import add from "date-fns/add";

const EventsFilterContext = createContext()

function EventsFilterProvider({children}) {
  const defaultFilters = {
    from: +new Date().setHours(0,0,0,0),
    to: 0,//+add(new Date().setHours(0,0,0,0), {weeks: 1}),
    country: '1|Россия',
    city: '',
    subscriptions: undefined
  }

  const [event, setEventsFilter] = useState(defaultFilters)

  const clearFilters = () => {
    setEventsFilter(defaultFilters)
  }

  const value = [event, setEventsFilter, clearFilters]
  return <EventsFilterContext.Provider value={value}>{children}</EventsFilterContext.Provider>
}

function useEventsFilter() {
  const context = useContext(EventsFilterContext)
  if (context === undefined) {
    throw new Error('useEventsFilter must be used within a EventsFilterProvider')
  }
  return context
}

export {EventsFilterProvider, useEventsFilter}
