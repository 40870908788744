const parseLaunchParams = (str) => str.split('&').map(p => p.split('=')).reduce((acc, [key, value]) => {
  acc[key] = value;
  return acc
}, {})

const checkVersion = (a, b) => {
  const [majorA, minorA] = String(a).split('.').map(v => Number.parseInt(v));
  const [majorB, minorB] = String(b).split('.').map(v => Number.parseInt(v));

  if (majorA !== majorB) {
    return majorA > majorB;
  }

  return minorA > minorB;
};

export {
  parseLaunchParams,
  checkVersion
}
